import { useFeatureFlagContext } from "../../FeatureFlags/featureFlags.context"
import { checkIfIsCoruscantMerchant } from "../utils/coruscantAccount"

const useFeatureFlag = (name: string) => {
  const ffContext = useFeatureFlagContext()
  const isFFActive = ffContext.featureFlags.find((ff) => ff.name === name)?.isActive

  return isFFActive
}

export const useIsFFCWTActive = () => {
  return useFeatureFlag("ALL.CWT")
}

export const useIsFFPINUPDATEActive = () => {
  return useFeatureFlag("DASHBOARD.PIN_UPDATE")
}

export const useIsFFBOV3Active = () => {
  return useFeatureFlag("DASHBOARD.BO_V3")
}

export const useIsBaUserMustBeOnboardedActive = () => {
  return useFeatureFlag("API.BA_USER_MUST_BE_ONBOARDED")
}

export const useIsBA2faActive = () => {
  return useFeatureFlag("API.BA_ACTIVATE_2FA")
}

export const useIsIssuingAllowingPhysicalCards = () => {
  return useFeatureFlag("ISSUING.DESKTOP_ALLOW_PHYSICAL_CARDS")
}

export const useIsYieldEnabled = () => {
  return useFeatureFlag("ALL.YIELD_ENABLED")
}

export const useIsBankStatementExportCSVActive = () => {
  return useFeatureFlag("DASHBOARD.BANK_STATEMENT_EXPORT_CSV")
}

export const useIsUserManagementAciveOnBA = () => {
  return useFeatureFlag("DASHBOARD.BA_WITH_USER_MANAGEMENT")
}

export const useIsBaBankStatementsActive = (merchantId: string) => {
  const isFFActive = useFeatureFlag("DASHBOARD.BA_BANK_STATEMENT")
  return isFFActive || checkIfIsCoruscantMerchant(merchantId)
}

export const useIsApRegulatedActive = () => {
  return useFeatureFlag("ALL.REGULATED_AP")
}
