import { gql, useQuery } from "@apollo/client"
import * as Sentry from "@sentry/react"
import { createContext, useCallback, useContext, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useAmplitude } from "../../00_shared/hooks/useAmplitude.hook"
import {
  useIsBaUserMustBeOnboardedActive,
  useIsFFBOV3Active,
  useIsUserManagementAciveOnBA,
} from "../../00_shared/hooks/useFeatureFlag.hook"
import { useAuthContext } from "../../Auth/auth.context"
import { MerchantOnboardingFlowStatus } from "../../business/enums/Merchant.enum"
import { IssuingCardsMenuEntry } from "../IssuingCards/routes/listMenuEntry"
import { ProAccountMenuEntry, TransfersMenuEntry } from "./routes/listMenuEntry"

type BusinessAccount = {
  name: string
  balance: number
  ledgerId: string
  virtualIbanId: string
  VIban: string
  BIC: string
  isMainAccount: boolean
  createdAt: string
}

type GetMerchantMainBusinessAccountResponse = {
  getMerchantMainBusinessAccount: { businessAccount: BusinessAccount[] }
}

const GET_MERCHANT_MAIN_BUSINESS_ACCOUNTS_QUERY = gql`
  query GetMerchantMainBusinessAccount {
    getMerchantMainBusinessAccount {
      ... on GetMerchantMainBusinessAccountOutput {
        businessAccount {
          name
          balance
          ledgerId
          virtualIbanId
          isMainAccount
          createdAt
        }
      }
      ... on SimpleApiError {
        errorCode
        message
      }
    }
  }
`

/**
 * @public
 */
export type BusinessAccountContextType = {
  isBaActive: boolean
  isBannerActive: boolean
  navigateToCreateTransfer: (options: { updateTransferOrigin: boolean }) => void
  returnToCreateTransferOrigin: () => void
  merchantMainAccountId?: string
  bannerHeight: number
  setBannerHeight: (height: number) => void
  createTransferOrigin: string | null
  isUserAllowedToCreateTransfers: boolean
  isUserAllowedToViewAccounts: boolean
  isUserAllowedToCreateBeneficiary: boolean
}

const BusinessAccountContext = createContext<BusinessAccountContextType>({} as BusinessAccountContextType)

export const useBusinessAccountContext = () => useContext(BusinessAccountContext)

export const BusinessAccountProvider: React.FC<{ children: JSX.Element | JSX.Element[] }> = ({ children }) => {
  const isBOV3FeatureFlagActive = useIsFFBOV3Active()
  const location = useLocation()
  const navigate = useNavigate()
  const [createTransferOrigin, setCreateTransferOrigin] = useState<null | string>(null)
  const { currentUser } = useAuthContext()
  const { track } = useAmplitude()

  const logAmplitude = useCallback((): void => {
    const pathEventMapping = [
      {
        path: "/accounts/transactions",
        event: "business_account_send_a_transfer_from_list_all_ba_clicked",
      },
      {
        path: "/accounts/account-",
        event: "business_account_select_an_account_send_a_transfer_clicked",
      },
      {
        path: "/transfers/transactions",
        event: "transfer_transactions.send_a_transfer",
      },
    ]

    const matchingPath = pathEventMapping.find(({ path }) => location.pathname.startsWith(path))

    if (matchingPath) {
      track(matchingPath.event)
    }
  }, [location.pathname, track])

  const navigateToCreateTransfer = useCallback(
    (options: { updateTransferOrigin: boolean }) => {
      if (options.updateTransferOrigin) {
        setCreateTransferOrigin(location.pathname)
      }

      logAmplitude()

      navigate("/transfers/create")
    },
    [location.pathname, navigate, logAmplitude],
  )

  const returnToCreateTransferOrigin = useCallback(() => {
    navigate(createTransferOrigin ?? "/transfers")
  }, [navigate, createTransferOrigin])

  const isActive = (path: string) => location.pathname.startsWith(path)

  const { error, data } = useQuery<GetMerchantMainBusinessAccountResponse>(GET_MERCHANT_MAIN_BUSINESS_ACCOUNTS_QUERY, {
    skip: !isBOV3FeatureFlagActive,
  })

  const [merchantMainAccountId, setMerchantMainAccountId] = useState<string | undefined>()
  const [isBaActive, setIsBaActive] = useState<boolean>(false)
  const [isBannerActive, setIsBannerActive] = useState<boolean>(false)
  const isBaUserMustOnboardedFFActive = useIsBaUserMustBeOnboardedActive()
  const [bannerHeight, setBannerHeight] = useState<number>(0)
  const isBaUserManagementFFActive = useIsUserManagementAciveOnBA()

  useEffect(() => {
    if (
      (currentUser.onboardingFlowStatuses.BA === MerchantOnboardingFlowStatus.PROCESSING ||
        currentUser.onboardingFlowStatuses.BA === MerchantOnboardingFlowStatus.NOT_STARTED) &&
      isBaUserMustOnboardedFFActive
    ) {
      setIsBannerActive(true)
      return
    }

    if (
      data?.getMerchantMainBusinessAccount.businessAccount.length &&
      currentUser.onboardingFlowStatuses.BA === MerchantOnboardingFlowStatus.VALIDATED
    ) {
      setIsBaActive(true)
      setMerchantMainAccountId(data.getMerchantMainBusinessAccount.businessAccount[0].ledgerId)
      return
    }
  }, [currentUser, data, isBaUserMustOnboardedFFActive])

  const isBaActiveWithOnBoardingFF = isBaUserMustOnboardedFFActive ? isBaActive : true
  const isOnActiveBannerRoute =
    isActive(ProAccountMenuEntry.to) || isActive(TransfersMenuEntry.to) || isActive(IssuingCardsMenuEntry.to)

  useEffect(() => {
    if (error) {
      Sentry.captureException(error, {
        extra: {
          reason: "An error occurred while fetching the main business account verification",
        },
      })
    }
  }, [error])

  return (
    <BusinessAccountContext.Provider
      value={{
        isBaActive: isBaActiveWithOnBoardingFF,
        isBannerActive: isOnActiveBannerRoute && isBannerActive,
        navigateToCreateTransfer,
        returnToCreateTransferOrigin,
        merchantMainAccountId,
        bannerHeight,
        setBannerHeight,
        createTransferOrigin,
        isUserAllowedToCreateTransfers: isBaUserManagementFFActive ? currentUser.isUserAllowedToSendWire : true,
        isUserAllowedToViewAccounts: isBaUserManagementFFActive
          ? currentUser.isUserAllowedToViewBusinessAccounts
          : true,
        isUserAllowedToCreateBeneficiary: isBaUserManagementFFActive ? currentUser.isUserAllowedToAddBeneficiary : true,
      }}
    >
      {children}
    </BusinessAccountContext.Provider>
  )
}
