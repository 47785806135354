import { DateTime } from "luxon"
import { languagesToLocale } from "./i18n"

/**
 * @deprecated use toLocalizedDate instead
 * @param d
 */
export const toFrenchDate = (d: string) => DateTime.fromISO(d).setLocale("fr").toLocaleString()

export const toLocalizedDate = (d: string, locale: string) =>
  DateTime.fromISO(d)
    .setLocale(languagesToLocale[locale] ?? "en-UK")
    .toLocaleString()

export const toLocalizedDateTime = (d: string, language: string) =>
  [
    toLocalizedDate(d, language),
    DateTime.fromISO(d)
      .setLocale(languagesToLocale[language] ?? "en-UK")
      .toLocaleString(DateTime.TIME_24_SIMPLE),
  ].join(" - ")

export enum DateStyle {
  NUMERIC,
  TEXTUAL,
}

export const toInternationalDate = ({
  date,
  language,
  style = DateStyle.NUMERIC,
}: {
  date: string | Date
  language: string
  style?: DateStyle
}) => {
  const locale = getEuropeanLocale(language)

  const LuxonDateTime = date instanceof Date ? DateTime.fromJSDate(date) : DateTime.fromISO(date)
  const options = style === DateStyle.NUMERIC ? DateTime.DATE_SHORT : DateTime.DATE_MED

  return LuxonDateTime.setLocale(locale).toLocaleString(options)
}

export const toInternationalDatetime = ({
  date,
  language,
  style = DateStyle.NUMERIC,
}: {
  date: string | Date
  language: string
  style?: DateStyle
}) => {
  const locale = getEuropeanLocale(language)

  const LuxonDateTime = date instanceof Date ? DateTime.fromJSDate(date) : DateTime.fromISO(date)
  const options = style === DateStyle.NUMERIC ? DateTime.DATETIME_SHORT : DateTime.DATETIME_MED

  return LuxonDateTime.setLocale(locale).toLocaleString(options)
}

const getEuropeanLocale = (language: string) => {
  if (language === "en") {
    return "en-UK"
  }

  if (language === "es") {
    return "es-ES"
  }

  if (language === "de") {
    return "de-DE"
  }

  if (language === "fr") {
    return "fr-FR"
  }

  if (language === "it") {
    return "it-IT"
  }

  return language
}

export const isDifferentUTCDay = (date1: string, date2: string) => {
  const d1 = DateTime.fromISO(date1).setZone("UTC")
  const d2 = DateTime.fromISO(date2).setZone("UTC")

  return !d1.hasSame(d2, "day")
}
