import { FilterIcon } from "../../../00_shared/components/krypton2/icons/icons"
import { Button } from "../../../00_shared/components/krypton2/Button"
import * as Popover from "@radix-ui/react-popover"
import { CommonTFunction, useCommonTranslation, useDashboardTranslation } from "../../../01_technical/translations"
import styled from "styled-components"
import { Field, RestoreIcon, Separator, Typography } from "@hero/krypton"
import { Spacer } from "../../../00_shared/components/Spacer"
import { StringSchema } from "../../../00_shared/utils/commonZodSchemas.utils"
import { z } from "zod"
import { Controller, useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { useAprContext } from "../AcceleratedPayoutRegulated.context"
import { formatDateStringForInput } from "../utils"

const getTableFiltersFormSchema = (t: CommonTFunction) => {
  return z.object({
    fromDate: StringSchema(t).nullable(),
    toDate: StringSchema(t).nullable(),
    minAmount: z.number().nullable(),
    maxAmount: z.number().nullable(),
    marketplaces: z.array(z.string()),
  })
}

const useTableFiltersForm = () => {
  const { t } = useCommonTranslation()
  const tableFiltersFormSchema = getTableFiltersFormSchema(t)
  type SchemaType = z.infer<typeof tableFiltersFormSchema>

  return useForm<SchemaType>({
    resolver: zodResolver(tableFiltersFormSchema),
  })
}

export const TableFilters = () => {
  const { t } = useDashboardTranslation()
  const form = useTableFiltersForm()
  const { setState, state } = useAprContext()

  return (
    <Popover.Root
      onOpenChange={(open) => {
        if (open) {
          form.reset(state.apTable.filters)
        }
      }}
    >
      <Popover.Trigger asChild>
        <Button $variant="secondary" LeftIcon={FilterIcon} size="small">
          {t("apRegulated.dashboard.filter")}
        </Button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content asChild sideOffset={8}>
          <FiltersWrapper>
            <Header>
              <Typography $variant="body-3-semibold">{t("apRegulated.dashboard.filter.title")}</Typography>
              <div style={{ flex: 1 }} />
              <Button
                LeftIcon={RestoreIcon}
                size="small"
                $variant="secondary"
                onClick={() => {
                  form.reset({
                    fromDate: null,
                    toDate: null,
                    minAmount: null,
                    maxAmount: null,
                    marketplaces: [],
                  })
                }}
              >
                {t("apRegulated.dashboard.filter.reset")}
              </Button>
            </Header>
            <Spacer />

            <Separator />
            <Spacer />
            <GroupTypography>{t("apRegulated.dashboard.filter.timePeriod")}</GroupTypography>
            <Spacer />

            <form
              onSubmit={form.handleSubmit(
                (data) => {
                  const newState = {
                    apTable: {
                      filters: {
                        fromDate: data.fromDate,
                        toDate: data.toDate,
                        minAmount: data.minAmount,
                        maxAmount: data.maxAmount,
                        marketplaces: data.marketplaces,
                      },
                    },
                  }

                  setState(newState)
                },
                (errors) => {
                  console.error(errors)
                },
              )}
            >
              <Row>
                <Controller
                  control={form.control}
                  name="fromDate"
                  render={({ field, fieldState }) => (
                    <Field
                      fieldLabel={t("apRegulated.dashboard.filter.fromDate")}
                      type="date"
                      $fullWidth
                      errorMessage={fieldState.error?.message}
                      onBlur={field.onBlur}
                      onChange={(e: React.FormEvent<HTMLInputElement>) =>
                        field.onChange(formatDateStringForInput(e.currentTarget.value))
                      }
                      value={field.value ?? ""}
                    />
                  )}
                />

                <Controller
                  control={form.control}
                  name="toDate"
                  render={({ field, fieldState }) => (
                    <Field
                      fieldLabel={t("apRegulated.dashboard.filter.toDate")}
                      type="date"
                      $fullWidth
                      errorMessage={fieldState.error?.message}
                      onBlur={field.onBlur}
                      onChange={(e: React.FormEvent<HTMLInputElement>) =>
                        field.onChange(formatDateStringForInput(e.currentTarget.value))
                      }
                      value={field.value ?? ""}
                    />
                  )}
                />
              </Row>

              <Separator />
              <Spacer />
              <GroupTypography>{t("apRegulated.dashboard.filter.amount")}</GroupTypography>
              <Spacer />

              <Row>
                <Controller
                  control={form.control}
                  name="minAmount"
                  render={({ field, fieldState }) => (
                    <Field
                      min={0}
                      fieldLabel={t("apRegulated.dashboard.filter.minAmount")}
                      type="number"
                      $fullWidth
                      errorMessage={fieldState.error?.message}
                      onBlur={field.onBlur}
                      onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        const value = parseInt(e.currentTarget.value, 10) || ""
                        return field.onChange(value)
                      }}
                      value={field.value ?? ""}
                      style={{
                        minWidth: "0!important",
                      }}
                    />
                  )}
                />

                <Controller
                  control={form.control}
                  name="maxAmount"
                  render={({ field, fieldState }) => (
                    <Field
                      min={0}
                      fieldLabel={t("apRegulated.dashboard.filter.maxAmount")}
                      type="number"
                      $fullWidth
                      errorMessage={fieldState.error?.message}
                      onBlur={field.onBlur}
                      onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        const value = parseInt(e.currentTarget.value, 10) || ""
                        return field.onChange(value)
                      }}
                      value={field.value ?? ""}
                      style={{
                        minWidth: "0!important",
                      }}
                    />
                  )}
                />
              </Row>

              {/* <Separator />
            <Spacer />
            <GroupTypography>{t("apRegulated.dashboard.filter.marketplaces")}</GroupTypography>
            <Spacer /> */}

              <Button type="submit" disabled={!form.formState.isValid} size="small" $fullWidth>
                {t("apRegulated.dashboard.filter.apply")}
              </Button>
            </form>
          </FiltersWrapper>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}

const Header = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`

const Row = styled.div`
  display: flex;
  gap: 1rem;

  & > * {
    flex: 1;
  }
`

const GroupTypography = styled(Typography).attrs({ $variant: "label-1" })`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.grey.$400};
`

const FiltersWrapper = styled.div`
  z-index: 1;
  background: #ffffff;
  border: 1px solid ${({ theme }) => theme.colors.grey.$100};
  box-shadow: 0px 2px 8px 0px #00000014;
  border-radius: 0.75rem;
  padding: 1rem;
  width: 21rem;
`
