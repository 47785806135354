import { Spacer } from "../../../00_shared/components/Spacer"
import { useDashboardTranslation } from "../../../01_technical/translations"
import {
  DashboardSectionHeader as Header,
  DashboardSectionHeaderIconWrapper as HeaderIconWrapper,
  DashboardSectionTitle as HeaderTitle,
  DashboardSection as Section,
} from "../../../00_shared/components/krypton2/DashboardSection"
import wave1 from "../assets/wave1.svg"
import wave2 from "../assets/wave2.svg"
import styled from "styled-components"
import { Typography } from "@hero/krypton"
import { useWatchWidth } from "../../../00_shared/hooks/useWatchWidth"
import { DateStyle, toInternationalDate } from "../../../00_shared/utils/date.converter"

const GRAPH_DAY_WIDTH = "4.25rem"
const GRAPH_GAP_BETWEEN_DAYS = "2rem"
const GRAPH_HORIZONTAL_PADDING = "2rem"

const remToPx = (rem: string) => parseFloat(rem.replace("rem", "")) * 16

const calculateNbOfDays = (contentWidth: number): number => {
  return (contentWidth + remToPx(GRAPH_GAP_BETWEEN_DAYS)) / (remToPx(GRAPH_DAY_WIDTH) + remToPx(GRAPH_GAP_BETWEEN_DAYS))
}

export const AcceleratedPayoutsGraphSection = () => {
  const { t, i18n } = useDashboardTranslation()
  const { observedEltWidth, observedEltRef } = useWatchWidth()

  const graphCoreContentWidth = observedEltWidth - 2 * remToPx(GRAPH_HORIZONTAL_PADDING)
  const nbOfDays = calculateNbOfDays(graphCoreContentWidth)

  const fakeData = [
    {
      date: "2025-01-01",
      periodRevenue: 2500,
      financedAmount: 2000,
      marketplaceTransfers: 0,
    },
    {
      date: "2025-01-02",
      periodRevenue: 3800,
      financedAmount: 3040,
      marketplaceTransfers: 0,
    },
    {
      date: "2025-01-03",
      periodRevenue: 4200,
      financedAmount: 3360,
      marketplaceTransfers: 4200 * 3,
    },
    {
      date: "2025-01-04",
      periodRevenue: 3100,
      financedAmount: 2480,
      marketplaceTransfers: 0,
    },
    {
      date: "2025-01-05",
      periodRevenue: 5600,
      financedAmount: 4480,
      marketplaceTransfers: 0,
    },
    {
      date: "2025-01-06",
      periodRevenue: 7800,
      financedAmount: 6240,
      marketplaceTransfers: 6240 * 3,
    },
    {
      date: "2025-01-07",
      periodRevenue: 6300,
      financedAmount: 5040,
      marketplaceTransfers: 0,
    },
    {
      date: "2025-01-08",
      periodRevenue: 4900,
      financedAmount: 3920,
      marketplaceTransfers: 0,
    },
    {
      date: "2025-01-09",
      periodRevenue: 8200,
      financedAmount: 6560,
      marketplaceTransfers: 0,
    },
    {
      date: "2025-01-10",
      periodRevenue: 9500,
      financedAmount: 7600,
      marketplaceTransfers: 0,
    },
    {
      date: "2025-01-11",
      periodRevenue: 7100,
      financedAmount: 5680,
      marketplaceTransfers: 0,
    },
    {
      date: "2025-01-12",
      periodRevenue: 5800,
      financedAmount: 4640,
      marketplaceTransfers: 3000,
    },
    {
      date: "2025-01-13",
      periodRevenue: 4100,
      financedAmount: 3280,
      marketplaceTransfers: 0,
    },
    {
      date: "2025-01-14",
      periodRevenue: 6700,
      financedAmount: 5360,
      marketplaceTransfers: 0,
    },
    {
      date: "2025-01-15",
      periodRevenue: 8900,
      financedAmount: 7120,
      marketplaceTransfers: 7120 * 3,
    },
    {
      date: "2025-01-16",
      periodRevenue: 7400,
      financedAmount: 5920,
      marketplaceTransfers: 0,
    },
  ].slice(0, nbOfDays)

  const maxValue =
    fakeData.reduce((acc, curr) => {
      const currMax = Math.max(curr.financedAmount, curr.marketplaceTransfers, curr.periodRevenue)
      if (currMax > acc) {
        return currMax
      }
      return acc
    }, 0) * 1

  const formatDate = (date: string) => {
    const currentYear = String(new Date().getFullYear())

    return toInternationalDate({ date, language: i18n.language, style: DateStyle.TEXTUAL })
      .replace(currentYear, "")
      .trim()
  }

  return (
    <Section>
      <Header>
        <HeaderTitle>
          <HeaderIconWrapper>GR</HeaderIconWrapper>
          {t("apRegulated.dashboard.graphSection.title")}
        </HeaderTitle>
      </Header>

      <Spacer $height="1.5rem" />

      <Graph>
        <HideSection>
          <Typography $variant="body-3-medium">COMING SOON</Typography>
        </HideSection>

        <GraphLeftLegend>
          <Legend>{formatLegendValue(maxValue)}</Legend>
          <Legend>{formatLegendValue((maxValue / 5) * 4)}</Legend>
          <Legend>{formatLegendValue((maxValue / 5) * 3)}</Legend>
          <Legend>{formatLegendValue((maxValue / 5) * 2)}</Legend>
          <Legend>{formatLegendValue((maxValue / 5) * 1)}</Legend>
          <Legend>0</Legend>
        </GraphLeftLegend>

        <GraphCore ref={observedEltRef}>
          <GraphCoreBackground>
            <BgLine />
            <BgLine />
            <BgLine />
            <BgLine />
            <BgLine />
            <BgLine />
          </GraphCoreBackground>

          {fakeData.map((bar) => {
            const periodRevenueHeightPercentage = getPercentage(bar.periodRevenue, maxValue)
            const financedAmountHeightPercentage = getPercentage(bar.financedAmount, maxValue)
            const marketplaceTransfersHeightPercentage = getPercentage(bar.marketplaceTransfers, maxValue)
            return (
              <Day>
                <BarGroup>
                  {Boolean(periodRevenueHeightPercentage) && (
                    <Bar value={periodRevenueHeightPercentage} color="#624AFF" wave={1} />
                  )}
                  {Boolean(financedAmountHeightPercentage) && (
                    <Bar value={financedAmountHeightPercentage} color="#C3C2FF" />
                  )}
                  {Boolean(marketplaceTransfersHeightPercentage) && (
                    <Bar value={marketplaceTransfersHeightPercentage} color="#FFBF8E" wave={2} />
                  )}
                </BarGroup>
                <BarLegend>{formatDate(bar.date)}</BarLegend>
              </Day>
            )
          })}
        </GraphCore>
      </Graph>
    </Section>
  )
}

const BAR_LEGEND_HEIGHT = "1.875rem"
const LEGEND_LINE_HEIGHT = "0.625rem"

const HideSection = styled.div`
  position: absolute;
  top: ${`-${BAR_LEGEND_HEIGHT}`};
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #ffffff82;
  z-index: 9;
  border-radius: 0.75rem;
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: center;
`

const getPercentage = (amount: number, max: number) => {
  return (amount / max) * 100
}

const formatLegendValue = (value: number): string => {
  /**
   * 2500 => 2,5K €
   * 2000 => 2,0K €
   * 1500 => 1,5K €
   * 1000 => 1,0K €
   * 500 => 0,5K €
   * 0 => 0,0K €
   */
  return (value / 1000).toFixed(1) + "K €"
}

const Day = styled.div`
  z-index: 1;
  flex: 1;
  justify-content: center;
  gap: 0.25rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.75rem;
  width: ${GRAPH_DAY_WIDTH};
`

const BarLegend = styled(Typography).attrs({
  $variant: "caption-2",
})`
  color: ${({ theme }) => theme.colors.grey.$400};
`

const Legend = styled(Typography).attrs({
  $variant: "caption-2",
})`
  line-height: ${LEGEND_LINE_HEIGHT};
  color: ${({ theme }) => theme.colors.grey.$400};
`

const LEFT_LEGEND_WIDTH = "3.25rem"
const GraphLeftLegend = styled.div`
  padding-right: 0.5rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: -${LEGEND_LINE_HEIGHT};
  width: ${LEFT_LEGEND_WIDTH};
  min-width: ${LEFT_LEGEND_WIDTH};
  margin-bottom: ${BAR_LEGEND_HEIGHT};
`

const Graph = styled.div`
  position: relative;
  display: flex;
  margin-top: ${LEGEND_LINE_HEIGHT};
`

const GraphCoreBackground = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  inset: 0;
  margin-bottom: ${BAR_LEGEND_HEIGHT};
`

const BgLine = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.grey.$200};
  z-index: 0;
`

const Bar = ({ value, color, wave }: { value: number; color: string; wave?: 1 | 2 }) => {
  const percentage = (value / 100) * 100
  return <BarWrapper height={`${percentage}%`} color={color} wave={wave}></BarWrapper>
}

const BarGroup = styled.div`
  z-index: 1;
  flex: 1;
  justify-content: center;
  gap: 0.25rem;
  display: flex;
  align-items: flex-end;
`

const BarWrapper = styled.div<{ height: string; color: string; wave?: 1 | 2 }>`
  width: 1.25rem;
  height: ${({ height }) => height};
  background-color: ${({ color }) => color};
  border-radius: 0.25rem;
  animation: expandBar 0.5s ease-out;
  transform-origin: bottom;

  background-image: url(${({ wave }) => (wave === 1 ? wave1 : wave === 2 ? wave2 : "")});
  background-size: 250%;
  background-position: 0 0;
  background-repeat: repeat;

  @keyframes expandBar {
    from {
      transform: scaleY(0);
    }
    to {
      transform: scaleY(1);
    }
  }
`

const GraphCore = styled.div`
  max-width: calc(100% - ${LEFT_LEGEND_WIDTH});
  flex: 1;
  position: relative;
  height: 16rem;
  gap: ${GRAPH_GAP_BETWEEN_DAYS};
  display: flex;
  padding: 0 ${GRAPH_HORIZONTAL_PADDING};
`
