import { useMemo } from "react"
import { useMutation, useQuery } from "@apollo/client"
import { ProductCapabilities } from "./UserManagementProductCapabilities"
import { useCommonTranslation, useDashboardTranslation } from "../../../01_technical/translations"
import { useBusinessAccountContext } from "../../BusinessAccount/businessAccount.context"
import { Separator, toaster, Typography } from "@hero/krypton"
import { Spacer } from "../../../00_shared/components/Spacer"
import { getTranslationKey } from "../00_shared/i18n"
import { Capability } from "../00_shared/Capability"
import { LIST_CAPABILITIES, UPDATE_CAPABILITY } from "../Capabilities.requests"
import { byPreset } from "../00_shared/rules/ProductCapabilities.rules"

export const SidePanelCapabilitiesStrategy = ({ actor }: { actor: string }) => {
  const { t } = useDashboardTranslation()
  const { t: ComonT } = useCommonTranslation()
  const { merchantMainAccountId } = useBusinessAccountContext()

  const [updateCapability] = useMutation(UPDATE_CAPABILITY)
  const { loading, error, data, refetch } = useQuery(LIST_CAPABILITIES, {
    variables: { actor },
    fetchPolicy: "network-only",
  })

  const updateCapabilitiesAndToaster = async (
    capabilities: (Omit<Capability, "translationKey"> & {
      actor: string
    })[],
  ) => {
    toaster.promise(
      updateCapability({ variables: { capabilities } }).then(() => refetch()),
      {
        pending: t("merchant.tools.setting.merchantUser.changeUserCapabilities.pending"),
        success: t("merchant.tools.setting.merchantUser.changeUserCapabilities.success"),
        error: t("merchant.tools.setting.merchantUser.changeUserCapabilities.error"),
      },
      { position: "top-center" },
    )
  }

  let fetchedCapabilities: Capability[] = []
  if (!loading && !error) {
    fetchedCapabilities = data.listCapabilities.capabilities.map(
      ({ action, target, isAllowed, friendlyTargetName }: Omit<Capability, "translationKey">) => ({
        action,
        target,
        friendlyTargetName:
          target === merchantMainAccountId ? ComonT("onboarding.businessAccount.name") : friendlyTargetName,
        isAllowed,
        translationKey: getTranslationKey({ action, target }),
      }),
    )
  }

  const capabilities = fetchedCapabilities.map((cap) => ({
    action: cap.action,
    target: cap.target,
    friendlyTargetName: cap.friendlyTargetName,
    isAllowed: cap.isAllowed,
    translationKey: cap.translationKey,
  }))

  const capabilityForAccounts = useMemo(() => byPreset(["view-account"], capabilities), [capabilities])
  /* Display only one capability for transfer */
  const otherCapabilities = useMemo(
    () => byPreset(["create-card", "add-beneficiary", "send-internal-transfer"], capabilities),
    [capabilities],
  )

  return (
    <>
      {capabilityForAccounts.length > 0 && (
        <>
          <Typography $variant="body-3-semibold">{t("userManagement.productStrategy.accounts.title")}</Typography>
          <Typography $variant="caption-2" $color="grey.$500">
            {t("userManagement.productStrategy.accounts.description")}
          </Typography>
          <Spacer />

          <ProductCapabilities
            capabilities={capabilityForAccounts.map((cap) => ({
              ...cap,
            }))}
            onChange={async ({ action, target, isAllowed }) => {
              await updateCapabilitiesAndToaster([{ actor, action, target, isAllowed }])
            }}
          />

          <Separator />
          <Spacer />
        </>
      )}

      <Typography $variant="body-3-semibold">{t("userManagement.productStrategy.permissions.title")}</Typography>
      <Spacer />
      <ProductCapabilities
        capabilities={otherCapabilities}
        onChange={async ({ action, target, isAllowed }) => {
          if (action === "send-internal-transfer") {
            // If the user is allowed to send external transfers, they should also be allowed to send internal transfers
            return updateCapabilitiesAndToaster([
              { actor, action, target, isAllowed },
              { actor, action: "send-external-transfer", target, isAllowed },
            ])
          }

          await updateCapabilitiesAndToaster([{ actor, action, target, isAllowed }])
        }}
      />
    </>
  )
}
