import * as Sentry from "@sentry/react"

export const useLogger = () => {
  const logError = (args: { what: string; why?: string; todo?: string; details?: Record<string, unknown> }) => {
    Sentry.captureException(args)
  }

  return {
    logError,
  }
}
