import { gql, useLazyQuery } from "@apollo/client"
import { toaster } from "@hero/krypton"

import { DateTime } from "luxon"
import { useDownloadFile } from "../../../../../00_shared/hooks/useDownloadFile"
import { useLogger } from "../../../../../01_technical/logger/useLogger"
import { useHeroQuery } from "../../../../../01_technical/requesting/useHeroQuery/useHeroQuery"
import { useCommonTranslation } from "../../../../../01_technical/translations"
import { useAuthContext } from "../../../../../Auth/auth.context"

const GET_MERCHANT_BANK_STATEMENT_QUERY = gql`
  query GetMerchantBankStatements($businessAccountLedgerId: String!, $page: Int!, $limit: Int!) {
    getMerchantBankStatements(pagination: { limit: $limit, page: $page }, businessAccountId: $businessAccountLedgerId) {
      ... on GetMerchantBankStatementsOutput {
        hasNext
        total
        limit
        currentPage
        bankStatements {
          id
          month
          year
        }
      }
      ... on GqlHeroError {
        errorCode
        message
        detail
      }
      ... on ValidationErrors {
        errorCode
      }
    }
  }
`

const GET_SIGNED_URL = gql`
  query GetBankStatementDownloadableLink($businessAccountLedgerId: String!, $bankStatementId: String!) {
    getBankStatementDownloadableLink(bankStatementId: $bankStatementId, businessAccountId: $businessAccountLedgerId) {
      ... on GetBankStatementDownloadableLinkOutput {
        signedUrl
      }
      ... on GqlHeroError {
        errorCode
        message
        detail
      }
      ... on ValidationErrors {
        errorCode
      }
    }
  }
`

const PAGE_LIMIT = 8

export const useBankStatement = ({
  businessAccountLedgerId,
  page,
}: {
  businessAccountLedgerId: string | undefined
  page: number
}) => {
  const { downloadFile } = useDownloadFile()
  const { currentUser } = useAuthContext()
  const { t } = useCommonTranslation()
  const { logError } = useLogger()

  const { data, refetch, loading, error } = useHeroQuery<{
    hasNext: boolean
    total: number
    limit: number
    currentPage: number
    bankStatements: {
      id: string
      month: number
      year: number
    }[]
  }>({
    gqlQuerySchema: GET_MERCHANT_BANK_STATEMENT_QUERY,
    variables: {
      businessAccountLedgerId: businessAccountLedgerId,
      page: page,
      limit: PAGE_LIMIT,
    },
    skip: !businessAccountLedgerId,
    fetchPolicy: "cache-and-network",
  })

  if (error) {
    toaster.error(t("ba.bankStatement.get.error"))
    const errorMessage = error instanceof Error ? error.message : String(error)
    logError({
      what: "Error while fetching bank statements",
      details: {
        error: errorMessage,
        data,
        businessAccountLedgerId,
      },
    })
  }

  const [getOperationJustificativePresignedUrl] = useLazyQuery<
    {
      getBankStatementDownloadableLink:
        | {
            signedUrl: string
            errorCode: undefined
            message: undefined
            detail: undefined
          }
        | {
            errorCode: string
            message: string
            detail: string
            signedUrl: undefined
          }
    },
    { bankStatementId: string; businessAccountLedgerId: string }
  >(GET_SIGNED_URL)

  const handleDownload = async (args: { bankStatementId: string; fileName: string }) => {
    const { bankStatementId, fileName } = args

    if (!businessAccountLedgerId) {
      return
    }
    const { data, error } = await getOperationJustificativePresignedUrl({
      variables: {
        businessAccountLedgerId,
        bankStatementId,
      },
    })

    if (data?.getBankStatementDownloadableLink.errorCode) {
      toaster.error(t("ba.bankStatement.download.error"))
      const errorMessage = error instanceof Error ? error.message : String(error)
      logError({
        what: "An error occurred while downloading the file bank statement",
        details: {
          error: errorMessage,
          data,
          businessAccountLedgerId,
        },
      })
    }

    if (data?.getBankStatementDownloadableLink.signedUrl) {
      await downloadFile(data.getBankStatementDownloadableLink.signedUrl, fileName)
      toaster.success(t("ba.bankStatement.download.success"))
    }

    if (error) {
      toaster.error(t("ba.bankStatement.download.error"))
      const errorMessage = error instanceof Error ? error.message : String(error)
      logError({
        what: "An error occurred while downloading the file bank statement",
        details: {
          error: errorMessage,
          data,
          businessAccountLedgerId,
        },
      })
    }
  }

  const mapBankStatementDate = (bankStatement: { id: string; month: number; year: number }) => {
    return {
      id: bankStatement.id,
      date: `${DateTime.fromObject({ month: bankStatement.month }).setLocale(currentUser.locale).toFormat("MMMM")} ${bankStatement.year}`,
      month: bankStatement.month,
      year: bankStatement.year,
    }
  }

  return {
    loading,
    bankStatement: data?.bankStatements.map(mapBankStatementDate) || [],
    totalPages: data?.total || 1,
    handleDownload,
    error,
    refetch,
  }
}
