import { DownloadIcon, Typography, theme } from "@hero/krypton"
import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import styled from "styled-components"
import { CardContent, CardHeader, CardTitle } from "../../../../00_shared/components/Card"
import { Dialog, DialogContent } from "../../../../00_shared/components/Dialog"
import useMediaQuery from "../../../../00_shared/hooks/useMediaQuery.hook"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { PaginationContainer } from "../../00_shared/components/PaginationContainer"
import { TableContainer } from "../../00_shared/components/TableContainer"
import { useBusinessAccount } from "../00_shared/hooks/useBusinessAccount"
import { useBankStatement } from "./hooks/useBankStatement.hook"

const CardHeaderContainer = styled(CardHeader)`
  @media (max-width: 768px) {
    padding: 1rem;
  }
`

const CardContentContainer = styled(CardContent)`
  padding: 0;
`

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0.8rem 1.5rem;
  text-transform: uppercase;
  background-color: ${theme.colors.grey.$100};
  border-bottom: 1px solid ${theme.colors.grey.$200};

  @media (max-width: 768px) {
    display: none;
  }
`

const SectionHeaderText = styled(Typography).attrs(() => ({
  $variant: "label-1",
}))`
  color: ${theme.colors.grey.$500};
`

const StatementList = styled.ul`
  list-style: none;

  display: flex;
  flex-direction: column;

  margin: 0;

  @media (max-width: 768px) {
    padding: 1rem 0 0 0;
  }
`

const StatementItem = styled.li`
  gap: 1rem;
  padding: 1rem 1.5rem 0 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;

  border-bottom: 1px solid ${theme.colors.grey.$200};

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background: ${theme.colors.grey.$100};
    cursor: pointer;
  }

  @media (max-width: 768px) {
    padding: 1rem;
  }
`

const StatementInfo = styled.div`
  display: flex;
  gap: 0.3rem;
  align-items: baseline;
`

const StatementDate = styled(Typography).attrs(() => ({
  $variant: "body-3-medium",
}))`
  color: ${theme.colors.grey.$600};
`

const Table = styled(TableContainer)`
  flex: 1;
`

const StatementsCard = () => {
  const { t } = useDashboardTranslation()
  const isMobile = useMediaQuery("(max-width: 768px)")
  const [page, setPage] = useState(1)
  const { accountNb } = useParams<{ accountNb: string }>()
  const { businessAccount, merchantInfo } = useBusinessAccount(accountNb)

  const { bankStatement, loading, totalPages, handleDownload } = useBankStatement({
    businessAccountLedgerId: businessAccount?.ledgerId,
    page,
  })

  return (
    <Table>
      <CardHeaderContainer>
        <CardTitle size={isMobile ? "title-3-semibold" : "body-2-semibold"}>
          {t("accounts.details.statements.header")}
        </CardTitle>
      </CardHeaderContainer>

      {!loading && (
        <>
          <CardContentContainer>
            <SectionHeader>
              <SectionHeaderText>{t("accounts.details.statements.sectionHeader")}</SectionHeaderText>
            </SectionHeader>

            <StatementList>
              {bankStatement.map((statement, index) => (
                <StatementItem
                  key={index}
                  onClick={() => {
                    const name = `${merchantInfo?.juridicalName} ${businessAccount?.name} ${statement.month.toString()}${statement.year.toString()}.pdf`
                    handleDownload({ bankStatementId: statement.id, fileName: name })
                  }}
                >
                  <StatementInfo>
                    <StatementDate>{statement.date}</StatementDate>
                  </StatementInfo>

                  <DownloadIcon />
                </StatementItem>
              ))}
            </StatementList>
          </CardContentContainer>
          <div style={{ flex: 1 }} />
          <PaginationContainer
            currentPage={page}
            totalPages={totalPages}
            onPageChange={(pageNumber: number) => setPage(pageNumber)}
          />
        </>
      )}
    </Table>
  )
}

export const AccountDetailsBankStatements = () => {
  const isMobile = useMediaQuery("(max-width: 768px)")
  const navigate = useNavigate()

  const preventAutoFocus = (event: Event) => {
    event.preventDefault()
  }
  const handleCloseOverlay = () => {
    navigate("..")
  }

  return (
    <>
      {!isMobile && <StatementsCard />}
      {isMobile && (
        <Dialog open={true} onOpenChange={handleCloseOverlay}>
          <DialogContent onOpenAutoFocus={preventAutoFocus} $disableAnimations={true} width="100%" height="100%">
            <StatementsCard />
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}
